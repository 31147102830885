import { useState } from 'react';
import {
  createStyles,
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  TextInput,
  rem,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch } from '@tabler/icons-react';

function Th( {children, reversed, sorted, onSort} ) {
    const { classes } = useStyles();
    const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
    return (
      <th className={classes.th}>
        <UnstyledButton onClick={onSort} className={classes.control}>
          <Group position="apart">
            <Text fw={500} fz="sm">
              {children}
            </Text>
            <Center className={classes.icon}>
              <Icon size="0.9rem" stroke={1.5} />
            </Center>
          </Group>
        </UnstyledButton>
      </th>
    );
}

function filterData(data, search) {
    const query = search.toLowerCase().trim();
    return data.filter((item) =>
      Object.keys(item).some((key) => {
        if (typeof item[key] === 'string' || item[key] instanceof String) {
          return item[key].toLowerCase().includes(query);
        }
        return false;
      })
    );
  }

function sortData(data, payload) {
    const { sortBy } = payload;
  
    if (!sortBy) {
      return filterData(data, payload.search);
    }
  
    return filterData(
      [...data].sort((a, b) => {
        if (payload.reversed) {
          return b[sortBy].localeCompare(a[sortBy]);
        }
  
        return a[sortBy].localeCompare(b[sortBy]);
      }),
      payload.search
    );
  }

const useStyles = createStyles((theme) => ({
    th: {
      padding: '0 !important',
    },
  
    control: {
      width: '100%',
      padding: `${theme.spacing.xs} ${theme.spacing.md}`,
  
      '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
      },
    },
  
    icon: {
      width: rem(21),
      height: rem(21),
      borderRadius: rem(21),
    },
  }));

export function TableSort({ data }) {

    const navigate = useNavigate();
    
    const [search, setSearch] = useState('');
    const [sortedData, setSortedData] = useState(data);
    const [sortBy, setSortBy] = useState(null);
    const [reverseSortDirection, setReverseSortDirection] = useState(false);
  
    const displayedKeys = ['name', 'location', 'startTime', 'endTime', 'user_id', 'meeting_id']

    const setSorting = (field) => {
      const reversed = field === sortBy ? !reverseSortDirection : false;
      setReverseSortDirection(reversed);
      setSortBy(field);
      setSortedData(sortData(data, { sortBy: field, reversed, search }));
    };
  
    const handleSearchChange = (event) => {
      const { value } = event.currentTarget;
      setSearch(value);
      setSortedData(sortData(data, { sortBy, reversed: reverseSortDirection, search: value }));
    };

    const rows = sortedData.map((row) => {
        return <tr key={row.meeting_id} onClick={() => {
            navigate("/meeting/"+row.meeting_id)
        }}>
        {displayedKeys.map((key) => {
            return <td key={key}>{row[key]}</td>
        })}
        </tr>
    }
    );

    function renderTableHeaders(displayedKeys) {
        return displayedKeys.map((key) => {
            return <Th
            key={key}
            sorted={sortBy === key}
            reversed={reverseSortDirection}
            onSort={() => setSorting(key)}
            >
                {key}
            </Th>
        })
    }
  
    if (data == null) {
        return null
    }
    return (
      <ScrollArea>
        <TextInput
          placeholder="Search by any field"
          mb="md"
          icon={<IconSearch size="0.9rem" stroke={1.5} />}
          value={search}
          onChange={handleSearchChange}
        />
        <Table striped highlightOnHover horizontalSpacing="md" verticalSpacing="xs" miw={700} >
          <thead>
            <tr>
                {renderTableHeaders(displayedKeys)}
            </tr>
          </thead>
          <tbody>
            {rows.length > 0 ? (
              rows
            ) : (
              <tr>
                {/* Object.keys(data[0]).length */}
                <td colSpan={displayedKeys.length}>
                  <Text weight={500} align="center">
                    Nothing found
                  </Text>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </ScrollArea>
    );
  }