import React, { useEffect, useState } from "react";
import TimeWrapper from "./TimeWrapper.js";
import TimeSelector from "./TimeSelector.js";
import TimeDisplay from "./TimeDisplay.js";
import { Avatar, Code, Title, Text, CopyButton, Button, Tooltip, Box, Container, Flex} from '@mantine/core';
import { IconClipboard, IconStar, IconUsers, IconCheck, IconMapPin, IconLink } from "@tabler/icons-react";
import { useAuth0 } from "@auth0/auth0-react";
import Login from "./Login.js";

export default function Meeting({host, updateMeetingAvailabilitiesClick, updateMeetingDetails, avatars, data, meeting_id, exists, name, description, location, link, startTime, endTime, selectedDays, availability, setAvailability, sendAvailability, avCount, totalAvCount, avUsers}){
    
    const { user, isAuthenticated} = useAuth0();
    const [hoveredIndex, setHoveredIndex] = useState(null); 
    
    function renderMeetingDetails() {
        if (exists) {
            return (
                <Box maw={400} mt='3rem'>
                    <Title order={1} fz={'3rem'} >{name}</Title>
                    
                    <CopyButton value={'https://momnt.dev/meeting/'+meeting_id} timeout={2000}>
                        {({ copied, copy }) => (
                            <Tooltip label={copied ? 'Copied!' : 'Copy'}>
                                <Button p='0' fw='500' gap='sm' variant='unfilled' onClick={copy}>
                                <Code pl='sm' pr='sm' mr='sm' color={copied ? 'green' : '#222222'} fz={'1.2rem'} >
                                    {meeting_id + ' '}
                                </Code>
                                {copied ? <IconCheck size={24} /> : <IconClipboard size={24}/> }
                                </Button>
                            </Tooltip>
                        )}
                    </CopyButton>
                    <Box mt='1rem'>
                        <Flex gap='sm' align={'center'}><IconStar size={20}/>
                            <Text fz="sm"> 
                                hosted by 
                            </Text>
                            <Avatar.Group>
                                <Tooltip label={host.name} withArrow>
                                    <Avatar src={host.picture} size='sm' radius="xl" />
                                </Tooltip>
                            </Avatar.Group>
                        </Flex>
                        {data.locationEnabled && <Flex gap='sm'><IconMapPin size={20}/><Text fz="sm"> at {location}</Text></Flex>}
                        {data.linkEnabled && <Flex gap='sm'><IconLink size={20}/><Text fz="sm"> {link}</Text></Flex>}
                        <Flex gap='sm' align={'center'}><IconUsers size={20}/>
                            <Text fz="sm"> 
                                {Object.keys(avatars).length} respondents
                            </Text>
                            <Avatar.Group>
                                {Object.keys(avatars).map((a) => <Tooltip label={avatars[a].name} withArrow>
                                    <Avatar src={avatars[a].picture} size='sm' radius="xl" />
                                </Tooltip>)}
                            </Avatar.Group>
                            
                        </Flex>
                        <Text mt="1rem" fz="sm"> {description} </Text>
                    </Box>      
                    {/* <Button onClick={copy} >
                        
                    </Button> */}
                    <br></br>
                </Box>
            );
        }
        else {
            return (<div>Meeting not found. Confirm that the meeting id and link are correct.</div>);
        }
    }

    useEffect(() => {
        console.log(avatars);
    }, [avatars])

    return (
        <div>
            {renderMeetingDetails()}
            <Title order={3}>
                availability: {(hoveredIndex || hoveredIndex === 0) && avCount[hoveredIndex] + '/' + totalAvCount}
            </Title>
            <div className='meeting'>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div>
                        <TimeWrapper startTime={startTime} endTime={endTime} selectedDays={selectedDays}>
                            <TimeDisplay timeCount={(endTime.substring(0, 2) - startTime.substring(0, 2))%24*2} dayCount={selectedDays.length} avCount={avCount} totalAvCount={totalAvCount} avUsers={avUsers} setHoveredIndex={setHoveredIndex}/>
                        </TimeWrapper>
                        <Button compact variant="subtle" color="dark" radius="md" size="xs" onClick={(e) => updateMeetingDetails()}>
                                    {'Refresh'}
                        </Button> 
                    </div>
                    
                </div>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    {isAuthenticated ? 
                    ( 
                    <div>
                        <TimeWrapper startTime={startTime} endTime={endTime} selectedDays={selectedDays}>
                            <TimeSelector availability={availability} setAvailability={setAvailability} timeCount={(endTime.substring(0, 2) - startTime.substring(0, 2))%24*2} dayCount={selectedDays.length}/>
                        </TimeWrapper>
                        <CopyButton value={'https://momnt.dev/meeting/'+meeting_id} timeout={1000}>
                        {({ copied, copy }) => (
                                <Button compact variant="subtle" color="dark" radius="md" size="xs" miw='4rem' onClick={() => {
                                    sendAvailability(user.sub);
                                    updateMeetingAvailabilitiesClick();
                                }}>
                                    
                                {copied ? <span><IconCheck size={10}/></span> : <span>Confirm</span> }
                                </Button>
                        )}
                        </CopyButton>
                        <Button compact variant="subtle" color="dark" radius="md" size="xs" onClick={(e) => setAvailability({})}>
                                    {'Clear'}
                        </Button> 
                    </div>) :
                    <Container >
                        <Text ta={'center'}>
                            To join the meeting,
                        </Text>
                        <Container sx={{display:'flex', justifyContent:'center'}}>
                            <Login/>
                        </Container>
                    </Container>  
                    }
                </div>
            </div>
            
            <Flex sx={{alignItems:'center'}}>
                <Text p='1rem'>Available:</Text>
                {(hoveredIndex || hoveredIndex === 0) && 
                <Avatar.Group>
                    {avUsers[hoveredIndex].map((e) =>   <Tooltip label={avatars[e].name} withArrow>
                                                            <Avatar src={avatars[e].picture} size='md' radius="xl" />
                                                        </Tooltip>)}
                </Avatar.Group>}

            </Flex>
            <Flex  >
                <Text p='1rem'>Unavailable:</Text>
                {(hoveredIndex || hoveredIndex === 0) && 
                <Avatar.Group>
                    {Object.keys(avatars).filter(n => !avUsers[hoveredIndex].includes(n)).map((e) =>   <Tooltip label={avatars[e].name} withArrow>
                                                            <Avatar src={avatars[e].picture} size='md' radius="xl" />
                                                        </Tooltip>)}
                </Avatar.Group>}
            </Flex>
        </div>
    );
}
