import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { TableSort } from "./TableSort.js";
import { Text, Tooltip, Flex} from '@mantine/core';
import { IconInfoCircle } from "@tabler/icons-react";

export default function AllMeetingFetcher(props){
    const [ allMeetings, setAllMeetings ] = useState([]);
    const [ joinedMeetings, setJoinedMeetings ] = useState([]);
    const [loading, setLoading] = useState(true);
    const { isLoading, user } = useAuth0();

    useEffect(()=>{
        const getUserMeetings = async () => {
            setLoading(true);
            console.log("https://api.momnt.dev");
            axios.get("https://api.momnt.dev"+'/usermeetings/'+user.sub)
                .then((response) => {
                    console.log(response);
                    setLoading(false);
                    setAllMeetings(response.data.host);
                    setJoinedMeetings(response.data.participant);
                })
                .catch(error => {
                    setLoading(false);
                    console.error(error)
                });
        } 
        if (!isLoading){
            getUserMeetings();
        }
    }, [isLoading, user.sub])

    if (isLoading || loading) {
        return <div style={{height: "100%", display: "flex", 'alignItems': "center", 'justifyContent': "center"}}>
                    <div className="spin"></div>
                </div>;
    }

    return <div>
        <Flex align='center' justify='space-between' mt={'2rem'}>
            <Text order={1} weight={600} fz={'2rem'}>Hosting </Text> 
            <Tooltip label="Meetings you've created.">
                <IconInfoCircle size={'1rem'}/>
            </Tooltip>
        </Flex>
        <TableSort data={allMeetings}/>
        <Flex align='center' justify='space-between' mt={'2rem'}>
            <Text order={1} weight={600} fz={'2rem'}>Joined </Text>
            <Tooltip label="Meetings you've submitted availabilities for.">
                <IconInfoCircle size={'1rem'}/>
            </Tooltip>
        </Flex>
        <TableSort data={joinedMeetings}/>
    </div> ;
}
