import React, { useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Button } from '@mantine/core';

const CustomCalendar = (props) => {
	dayjs.extend(utc);
	dayjs.extend(timezone);
	const [currentMonth, setCurrentMonth] = useState(dayjs().startOf('month'));

	const handleDayClick = (day) => {
		if (props.selectedDays.some(d => dayjs(d).isSame(day, 'day'))) {
			props.setSelectedDays(props.selectedDays.filter(d => !dayjs(d).isSame(day, 'day')));
		} else {
			if (props.selectedDays.length < 7) {
				props.setSelectedDays([...props.selectedDays, day].sort((a, b) => {
					return dayjs(a).isAfter(b) ? 1 : -1
				}));
			}
		}
	};

	const handlePreviousMonthClick = (e) => {
		e.preventDefault();
		setCurrentMonth(currentMonth.subtract(1, 'month'));
	}
	const handleNextMonthClick = (e) => {
		e.preventDefault();
		setCurrentMonth(currentMonth.add(1, 'month').startOf('month'));
	}
	const handleTodayClick = (e) => {
		e.preventDefault();
		setCurrentMonth(dayjs().startOf('month'));
	}

	const renderCalendarMonthYear = () => {
		const months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
		return 	<div> 
					<div style={{textAlign:'center'}} className="calendar-monthyear">
						{months[currentMonth.month()] + " " + currentMonth.year()}
					</div>
					<div style={{display: 'flex', justifyContent: 'space-between'}} className="calendar-header"> 
						<Button compact variant="subtle" color="dark" radius="md" size="xs" onClick={() => props.setSelectedDays([])}>
								{'Clear'}
						</Button>
						
						<div style={{display: 'flex', justifyContent: 'space-between'}}>
							<Button compact variant="subtle" color="dark" radius="md" size="xs" onClick={handlePreviousMonthClick}>
								{'<'}
							</Button>
							<Button compact variant="subtle" color="dark" radius="md" size="xs" onClick={handleTodayClick}>
								{'Today'}
							</Button>
							<Button compact variant="subtle" color="dark" radius="md" size="xs" onClick={handleNextMonthClick}>
								{'>'}
							</Button>
						</div>
					</div>
				</div>
	}

	const renderCalendarDayOfWeek = () => {
		const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
		const o = [];
		for (let i = 0; i < 7; i++){
			o.push(
				<div className="cal-element cal-weekday" key={weekDays[i]}>
					{weekDays[i]}
				</div>
			);
		}
		return o;
	}

	const renderCalendarDays = () => {
		const today = dayjs().startOf('day');
		const endOfMonth = currentMonth.endOf('month');
		const days = [];

		// Render blank spots to adjust for day of week
		for (let day = 0; day < currentMonth.day(); day ++){
			days.push(
				<div key={day}>

				</div>
			)
		}

		// Render dates in currentMonth
		for (let day = currentMonth; day <= endOfMonth; day = dayjs(day).add(1, 'day')) {
			const isToday = day.isSame(today, 'day');
			const isSelected = props.selectedDays.some(d => dayjs(d).isSame(day, 'day'));
			const classNames = [
				'cal-element', 
				'calendar-day',
				isToday && 'calendar-day--today',
				isSelected && 'calendar-day--selected'
			].filter(Boolean).join(' ');

			days.push(
				<div
					key={day.format('YYYY-MM-DD')}
					className={classNames}
					onClick={() => handleDayClick(day)}
				>
					{day.date()}
				</div>
			);
		}
		return days;
  	};

	return (
		<div className="calendar-container">
			<div className="calendar-header">
				{renderCalendarMonthYear()}
			</div>
			<div className="calendar-body">
				{renderCalendarDayOfWeek()}
				{renderCalendarDays()}
			</div>
			<div>
				Selected Days ({props.selectedDays.length + '/7'}):
				<div>
				{props.selectedDays.map((tag, i) => {
					return i < props.selectedDays.length-1 ? <span>{tag.format('M/D')}, </span>:<span>{tag.format('M/D')}</span>
				})}
				</div>
			</div>
		</div>
	);
};

export default CustomCalendar;
