// import io from 'socket.io-client';
import React, { createContext }  from 'react';
import CreateMeeting from './components/CreateMeeting.js'
import ErrorPage from "./routes/ErrorPage.jsx";
import './App.css'
import { createBrowserRouter,RouterProvider, useNavigate } from 'react-router-dom';
import MeetingFetcher from './components/MeetingFetcher.js';
import Root from "./routes/root";
import Profile from './components/Profile.js'; 
import { Auth0Provider, withAuthenticationRequired } from "@auth0/auth0-react";
import Login from './components/Login.js';
import AllMeetingFetcher from './components/AllMeetingFetcher.js';

export const SocketContext = createContext();

const ProtectedRoute = ({ component, ...args }) => {
	const Component = withAuthenticationRequired(component, args);
	return <Component />;
};
  
const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
	const navigate = useNavigate();
	const onRedirectCallback = (appState) => {
		navigate((appState && appState.returnTo) || window.location.pathname);
	};
	return (
		<Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
			{children}
		</Auth0Provider>
	);
};
  

const router = createBrowserRouter([
	{
		path: "/",
			element:<Auth0ProviderWithRedirectCallback
			domain="dev-70t7ddguzrjj1vcd.us.auth0.com"
			clientId="gaouZhIQl5KLyWFltPw2Ct2ywnfJJ7wj"
			authorizationParams={{
			redirect_uri: window.location.origin,
			}}
			>
				<Root />
			</Auth0ProviderWithRedirectCallback>,
		errorElement: <ErrorPage />,
		children: [
			{
				path: "/new",
				element: <ProtectedRoute component={CreateMeeting} />,
			},
			{
				path: "/meeting",
				element: <ProtectedRoute component={AllMeetingFetcher} />,
			},
			{
				path: "/meeting/:meeting_id",
				element: <MeetingFetcher />,
			},
			{
				path: "/profile",
				element: <Profile />,
			},
			{
				path: "/login",
				element: <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%'}}><Login /></div>,
			},
		]
	}, 
])

function App() {
	// const {user, isAuthenticated, isLoading, logout, loginWithPopup} = useAuth0();
	// const [socket, setSocket] = useState(null);

	// useEffect(() => {
	// 	const newSocket = io("http://localhost:3001");
	// 	// newSocket.on("new user", (userList) => {
	// 	// 	setUsers(userList);
	// 	// });
	// 	setSocket(newSocket);
	// 	return () => newSocket.close()
	// }, [setSocket]);

	return (
		<RouterProvider router={router} />
		// <SocketContext.Provider value={socket}>
		// </SocketContext.Provider>
	)
}
    
export default App;