import * as React from "react";

export default React.forwardRef((props, ref) => {
    return(
        <div 
            ref={ref}
            className={'select-box ' + (props.isSelected ? 'selected ' : '') + props.corner} 
            style={{backgroundColor: '#222222' + (Math.floor(props.avCount/props.totalAvCount*255)).toString(16)}}
        >
            {props.children}
        </div>
    )
})