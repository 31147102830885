import React, { useEffect, useState } from "react";
import CustomCalendar from "./CustomCalendar.js";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { TextInput, Textarea, Select, Checkbox, Button, Group, Box, Input} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useAuth0 } from "@auth0/auth0-react";

const times = [
    { value: '00', label: '12:00 AM' },
    { value: '01', label: '01:00 AM' },
    { value: '02', label: '02:00 AM' },
    { value: '03', label: '03:00 AM' },
    { value: '04', label: '04:00 AM' },
    { value: '05', label: '05:00 AM' },
    { value: '06', label: '06:00 AM' },
    { value: '07', label: '07:00 AM' },
    { value: '08', label: '08:00 AM' },
    { value: '09', label: '09:00 AM' },
    { value: '10', label: '10:00 AM' },
    { value: '11', label: '11:00 AM' },
    { value: '12', label: '12:00 PM' },
    { value: '13', label: '01:00 PM' },
    { value: '14', label: '02:00 PM' },
    { value: '15', label: '03:00 PM' },
    { value: '16', label: '04:00 PM' },
    { value: '17', label: '05:00 PM' },
    { value: '18', label: '06:00 PM' },
    { value: '19', label: '07:00 PM' },
    { value: '20', label: '08:00 PM' },
    { value: '21', label: '09:00 PM' },
    { value: '22', label: '10:00 PM' },
    { value: '23', label: '11:00 PM' },
  ]

export default function CreateMeeting(props){
    const { isLoading, user } = useAuth0();
    const navigate = useNavigate();
    const [selectedDays, setSelectedDays] = useState([]);

    const [locationEnabled, setLocationEnabled] = useState(false);
    const [linkEnabled, setLinkEnabled] = useState(false);

    const form = useForm({
        initialValues: {
            name: '',
            description: '',
            locationEnabled: false,
            location: '',
            linkEnabled: false,
            link: '',
            startTime: '',
            endTime: '',
            selectedDays: [],
            user_id: '',
        },
    
        validate: {
            selectedDays: (value) => (value.length === 0 ? 'Choose at least one day!' : null),
            location: (value) => ((value === '' && locationEnabled) ? 'Please fill in the field.' : null),
            link: (value) => ((value === '' && linkEnabled) ? 'Please fill in the field.' : null),
            endTime: (value, values) => ((value <= values.startTime) ? 'Please select a time after the earliest time.' : null),
        },
    });

    const handleSubmit = (values) => {
        const info = {
            name: values.name,
            description: values.description, 
            location: values.location,
            locationEnabled: values.locationEnabled,
            link: values.link,
            linkEnabled: values.linkEnabled,
            startTime: values.startTime,
            endTime: values.endTime,
            selectedDays: values.selectedDays,
            user_id: values.user_id
        };
        axios.post("https://api.momnt.dev"+'/createmeeting', info)
            .then((response) => {
                console.log(response.data);
                navigate("/meeting/"+response.data);
            })
            .catch(error => console.error(error));
    };

    useEffect(() => {
        form.setValues({
            selectedDays: selectedDays
        })// eslint-disable-next-line
    }, [selectedDays])

    useEffect(() => {
        if (!isLoading) {
            form.setValues({
                user_id: user.sub,
            })
        }// eslint-disable-next-line
    }, [isLoading])
    
    return (
        <Box maw={500} miw={300} mx="auto">
            <h1>New Meeting</h1>
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                <TextInput
                    label="Meeting Name"
                    placeholder="Review Session"
                    {...form.getInputProps('name')}
                    withAsterisk
                    required
                />
                <Textarea
                    required
                    withAsterisk
                    mt="1rem"
                    placeholder="A 15 minute meeting to review... "
                    label="Description"
                    {...form.getInputProps('description')}
                />
                
                <Checkbox 
                    label="Add Location" 
                    mt="1.5rem"
                    checked={locationEnabled} 
                    onChange={(event) => {
                        setLocationEnabled(event.currentTarget.checked);
                        form.setValues({
                            locationEnabled: event.currentTarget.checked
                        })
                    }} 
                />
                {locationEnabled && <TextInput
                    Enabled={!locationEnabled}
                    label="Location"
                    placeholder="111 Arthur Rd San Francisco, CA"
                    {...form.getInputProps('location')}
                />}
                <Checkbox 
                    label="Add Link"
                    mt="1.5rem"
                    checked={linkEnabled} 
                    onChange={(event) => {
                        setLinkEnabled(event.currentTarget.checked);
                        form.setValues({
                            linkEnabled: event.currentTarget.checked
                        })
                    }} 
                />
                {linkEnabled && <TextInput
                    Enabled={!linkEnabled}
                    label="Link"
                    placeholder="https://example.com/51432"
                    {...form.getInputProps('link')}
                />}
                
                <Group mt="1rem">
                <Select
                    withAsterisk
                    required
                    label="Earliest time"
                    placeholder="6:00 AM"
                    data={times}
                    {...form.getInputProps('startTime')}
                />
                <Select
                    withAsterisk
                    required
                    label="Latest time"
                    placeholder="3:00 PM"
                    data={times}
                    {...form.getInputProps('endTime')}
                />
                </Group>
                
                <Input.Wrapper 
                    mt="1rem" 
                    id="selectedDays" 
                    label="Days" 
                    description="Choose up to 7 potential days for your meeting." 
                    required 
                    mx="auto"
                    inputWrapperOrder={['label', 'description', 'error', 'input']}
                    {...form.getInputProps('selectedDays')}
                >
                    <CustomCalendar selectedDays={selectedDays} setSelectedDays={setSelectedDays}></CustomCalendar>
                </Input.Wrapper>
            <Group position="right" mt="md">
              <Button type="submit">Submit</Button>
            </Group>
          </form>
        </Box>
      );
}