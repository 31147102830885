import * as React from "react";
import SelectionBox from '../components/SelectionBox.js';
import TimeSelectorSlot from '../components/TimeSelectorSlot.js';

export default function TimeSelector({timeCount, dayCount, availability, setAvailability}){
    const timeSlotsRef = React.useRef([]);
    const [mouseDown, setMouseDown] = React.useState(false);
    const [appendMode, setAppendMode] = React.useState(true);
    const [startPoint, setStartPoint] = React.useState({x:1, y:1});
    // const [endPoint, setEndPoint] = React.useState({x:2, y:2});
    const [selectionBox, setSelectionBox] = React.useState(null);

    const handleMouseDown = (e) => {
        setMouseDown(true);
        var tempPoint={
            x: e.pageX,
            y: e.pageY
        };
        setStartPoint(tempPoint);
        setAppendMode(calculateAppendMode(tempPoint));
        setSelectionBox(calculateSelectionBoxOutlines(tempPoint, tempPoint));
    }

    // useEffect function called on mouseDown event that adds correct document event listeners
    React.useEffect(() => {
        const handleMouseUp = (e) => {
            setMouseDown(false);
            setStartPoint(null);
            setSelectionBox(null);
        }

        const handleMouseMove = (e) => {
            e.preventDefault();
            var tempEndPoint = {
                x: e.pageX,
                y: e.pageY
            }
            setSelectionBox(calculateSelectionBoxOutlines(startPoint, tempEndPoint));
        };

        if (mouseDown){
            document.addEventListener("mousemove", handleMouseMove);
            document.addEventListener("mouseup", handleMouseUp);;
        }
        return function cleanup(){
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
        }
    }, [mouseDown, startPoint])

    // useEffect function called on selectionBox change event that updates selectedItems
    React.useEffect(() => {
        // only activate when selectionBox exists
        if (selectionBox != null){
            // loop through all TimeSlot references, checking if the selectionBox and TimeSlot intersect
            // if so, update the TimeSlot selected status using selectItem function
            for (let i = 0; i < timeSlotsRef.current.length; i++){
                var rect = timeSlotsRef.current[i].getBoundingClientRect();
                var tmpBox = {
                    left: rect.left,
                    top: rect.top,
                    width: rect.right-rect.left,
                    height: rect.bottom-rect.top,
                };
                // var tmpBox = {
                //     left: timeSlotsRef.current[i].offsetLeft,
                //     top: timeSlotsRef.current[i].offsetTop,
                //     width: timeSlotsRef.current[i].offsetWidth,
                //     height: timeSlotsRef.current[i].offsetHeight,
                // };
                if (boxIntersects(selectionBox, tmpBox)){
                    selectItem(i);
                }
            }
        } // eslint-disable-next-line 
    }, [selectionBox]);

    // calculate if appendMode should be turned on based on if a point is inside a selected TimeSlot
    function calculateAppendMode(point){
        for (let i = 0; i < timeSlotsRef.current.length; i++){
            var rect = timeSlotsRef.current[i].getBoundingClientRect();
            if (point.x <= rect.right &&
                point.x >= rect.left &&
                point.y <= rect.bottom &&
                point.y >= rect.top)
            {
                if (i in availability){
                    return false;
                } else{
                    return true;
                }
            }
        }
        return true;
    }

    // returns if two boxes intersect each other
    function boxIntersects (boxA, boxB) {
        if(boxA.left <= boxB.left + boxB.width &&
          boxA.left + boxA.width >= boxB.left &&
          boxA.top <= boxB.top + boxB.height &&
          boxA.top + boxA.height >= boxB.top) {
          return true;
        }
        return false;
    }

    // update selectedItems using key (reference index) and appendMode
    function selectItem(key) {
        var oldAvailability=availability;
        if (appendMode){
            oldAvailability[key] = true;
            setAvailability(oldAvailability);
        } else {
            if (key in oldAvailability){
                delete oldAvailability[key];
            }
            setAvailability(oldAvailability);
        }
    }

    // calculate box dimensions based on start and end points
    function calculateSelectionBoxOutlines(startPoint, endPoint){
        var left = Math.min(startPoint.x, endPoint.x);
        var top = Math.min(startPoint.y, endPoint.y);
        var width = Math.abs(startPoint.x - endPoint.x);
        var height = Math.abs(startPoint.y - endPoint.y);
        return {
        left: left,
        top: top,
        width: width,
        height: height
        };
    }

    /// Render Functions
    function renderSelectionBox(){
        if (!mouseDown){
            return null
        }
        return <SelectionBox key={-1} Style={selectionBox}/>;
    }

    function renderChildren(){
        return Array.from({length: dayCount*timeCount}, (v, i) => i).map((i) => {
            var corner = '';
            if (dayCount === 1 && timeCount === 1) {
                corner += 'single'
            } else if (dayCount === 1) {
                if (i === 0){
                    corner += 'top';
                } else if (i === dayCount*timeCount-1) {
                    corner += 'bottom';
                }
            } else if (timeCount === 1) {
                if (i === 0){
                    corner += 'left';
                } else if (i === dayCount*timeCount-1) {
                    corner += 'right';
                }
            } else {
                if (i === 0){
                    corner += 'topleft';
                } else if  (i === dayCount-1) {
                    corner += 'topright';
                } else if (i === dayCount*timeCount-dayCount) {
                    corner += 'bottomleft';
                } else if (i === dayCount*timeCount-1) {
                    corner += 'bottomright';
                } 
            }
            if (Math.floor(i/dayCount)%2 === 0) {
                corner += ' solidtop';
            } 
            if (i >= dayCount*timeCount-dayCount) {
                corner += ' solidbottom';
            }
            return <TimeSelectorSlot key={i} corner={corner} ref={e => timeSlotsRef.current[i] = e} isSelected={i in availability}/>
        });
    }

    // Component
    return(
    <div onMouseDown={handleMouseDown} className={'selection'} style={
        {
            'gridTemplateRows': 'repeat('+timeCount.toString()+', 1fr)',
            'gridTemplateColumns': 'repeat('+dayCount.toString()+', 1fr)',
        }
    }>
        {renderSelectionBox()}
        {renderChildren()}
    </div>)
}