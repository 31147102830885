import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Text, Group} from '@mantine/core';
import { IconUserCircle } from '@tabler/icons-react';
  
const Login = () => {
    const { loginWithRedirect } = useAuth0();

    return <Button mih="2rem" variant="gradient" onClick={() => loginWithRedirect({
        authorizationParams: {
            redirect_uri: 'https://momnt.dev/'
        },
        appState: {
            returnTo: window.location.pathname // here
        }
      }
    )}>
        <Group sx={{justifyContent:'left'}}>
            <IconUserCircle color="white"/>
            <div style={{ flex: 1 }}>
                <Text color="white" size="sm" weight={500}>
                    Login
                </Text>
            </div>
        </Group>
    </Button>
}

export default Login;