import React from "react";
import TimeDisplaySlot from '../components/TimeDisplaySlot.js';

export default function TimeDisplay({timeCount, dayCount, avCount, totalAvCount, avUsers, setHoveredIndex}){

    function renderChildren(){
        return Array.from({length: dayCount*timeCount}, (v, i) => i).map((i) => {
            var corner = '';
            if (dayCount === 1 && timeCount === 1) {
                corner += 'single'
            } else if (dayCount === 1) {
                if (i === 0){
                    corner += 'top';
                } else if (i === dayCount*timeCount-1) {
                    corner += 'bottom';
                }
            } else if (timeCount === 1) {
                if (i === 0){
                    corner += 'left';
                } else if (i === dayCount*timeCount-1) {
                    corner += 'right';
                }
            } else {
                if (i === 0){
                    corner += 'topleft';
                } else if  (i === dayCount-1) {
                    corner += 'topright';
                } else if (i === dayCount*timeCount-dayCount) {
                    corner += 'bottomleft';
                } else if (i === dayCount*timeCount-1) {
                    corner += 'bottomright';
                } 
            }
            if (Math.floor(i/dayCount)%2 === 0) {
                corner += ' solidtop';
            } 
            if (i >= dayCount*timeCount-dayCount) {
                corner += ' solidbottom';
            }
            return <TimeDisplaySlot corner={corner} slotIndex={i} key={i} avCount={avCount[i]} totalAvCount={totalAvCount} setHoveredIndex={setHoveredIndex}/>
        });
    }

    return(
        <div className={'selection'} style={
            {
                'gridTemplateRows': 'repeat('+(timeCount).toString()+', 1fr)',
                'gridTemplateColumns': 'repeat('+(dayCount).toString()+', 1fr)',
            }
        }>
            {renderChildren()}
        </div>
    )
}