import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Menu, Button, Text, Group } from '@mantine/core';
import { IconChevronDown, IconUserCircle } from '@tabler/icons-react';
  

const Profile = () => {
  const { user, isAuthenticated, isLoading, logout, loginWithRedirect } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (!isAuthenticated) {
    return <Button variant="unstyled" mih="3rem" sx={{padding:0}} onClick={() => loginWithRedirect({
        authorizationParams: {
            redirect_uri: 'https://momnt.dev/'
        },
        appState: {
            returnTo: window.location.pathname // here
        }
      }
    )}>
    <Group sx={{justifyContent:'left'}}>
        <IconUserCircle color="white"/>
        <div style={{ flex: 1 }}>
            <Text color="white" size="sm" weight={500}>
                Login
            </Text>
        </div>
    </Group>
    </Button>
  }

  return (
        <Menu>
            <Menu.Target>
                {/* <div style={{display:'flex', justifyContent:'left', alignContent:'center'}}>
                    <Avatar src={user.picture} alt={user.name} color="blue" radius="xl">
                        <IconStar size="1.5rem" />
                    </Avatar>
                    <IconChevronDown size={12}/>
                </div> */}
                <div>

                
                <Button variant="unstyled" mih="3rem" sx={{padding:0}} >
                <Group sx={{justifyContent:'left'}}>
                    <Avatar src={user.picture} radius="xl" size="md" />
                    <div style={{ flex: 1 }}>
                        <Text color="white" size="sm" weight={500}>
                            Welcome,
                        </Text>
                        <Text color="dimmed" size="xs">
                            {user.name}
                        </Text>
                    </div>
                    <IconChevronDown color="white" size={14}/>
                </Group>
                </Button>
                </div>
            </Menu.Target>
            {/* <Menu.Dropdown>
                <Menu.Item>Signed in as <strong>{user.name}</strong></Menu.Item>
                <Menu.Divider />
                <Menu.Item icon={<IconUserCircle size={14} />}>Account</Menu.Item>
                <Menu.Item icon={<IconSettings size={14} />}>Settings</Menu.Item>
                <Menu.Divider />
                <Menu.Item icon={<IconQuestionMark size={14} />}>Help</Menu.Item>
                <Menu.Item icon={<IconArrowUp size={14} />}>Upgrade</Menu.Item>
                <Menu.Divider />
                <Menu.Item icon={<IconDoorExit size={14}/>}>
                    <div onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                        Sign out
                    </div>
                </Menu.Item>
            </Menu.Dropdown> */}
            <Menu.Dropdown>
                <Menu.Item>Signed in as <strong>{user.name}</strong></Menu.Item>
                {/* <Menu.Divider />
                <Menu.Item icon="👤">Account</Menu.Item>
                <Menu.Item icon="⚙️">Settings</Menu.Item>
                <Menu.Divider /> */}
                <Menu.Item icon="❓">Help</Menu.Item>
                {/* <Menu.Item icon="⭐">Upgrade</Menu.Item> */}
                <Menu.Divider />
                <Menu.Item icon="🚪">
                    <div onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                        Sign out
                    </div>
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
  );
};

export default Profile;