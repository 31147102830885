import React, { useEffect, useState } from "react";
// import {SocketContext} from '../App.js'
import { useParams } from 'react-router-dom'
import Meeting from "./Meeting.js";
import axios from "axios";
import dayjs from 'dayjs';
import { useAuth0 } from "@auth0/auth0-react";

export default function MeetingFetcher(props){
    const { meeting_id } = useParams();
    const [loading, setLoading] = useState(true);
    const [exists, setExists] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [location, setLocation] = useState('');
    const [link, setLink] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [selectedDays, setSelectedDays] = useState([]);
    const [data, setData] = useState([]);
    const [availability, setAvailability] = useState({})
    const [meetingAvailabilities, setMeetingAvailabilities] = useState([]);
    const [avCount, setAvCount] = useState([]);
    const [avUsers, setAvUsers] = useState([]);
    const [users, setUsers] = useState({});
    const [host, setHost] = useState({});
    const { user, isAuthenticated, isLoading } = useAuth0();
    
    const updateMeetingAvailabilities = () => {
        const l = (endTime.substring(0, 2) - startTime.substring(0, 2))%24*2 * selectedDays.length
        const tempAvCount = [];
        const tempAvUsers = Array.from({length: l}, (v, i) => [])
        for (let i = 0; i < l; i++){
            let temp = 0
            for (const entry of meetingAvailabilities){
                if (i in entry.availability) {
                    temp ++; 
                    tempAvUsers[i].push(entry.user);
                }
            }
            tempAvCount.push(temp);
        } 
        setAvCount(tempAvCount);
        setAvUsers(tempAvUsers);

        if (!isLoading && isAuthenticated && meetingAvailabilities) {
            const currentUserAvailabilities = meetingAvailabilities.find((e) => e.user === user.sub);
            if (!!currentUserAvailabilities){
                setAvailability(meetingAvailabilities.find((e) => e.user === user.sub).availability);
            }
        }
    }

    const updateMeetingAvailabilitiesClick = () => {
        setMeetingAvailabilities(meetingAvailabilities.map((x) => {
            if (x.user_id === user.sub){
                return {
                    ...x,
                    availability: availability
                }
            }
            else{
                return x
            }
        }))
    }
    
    const updateMeetingDetails = async () => {
        setLoading(true);
        // 'https://api.momnt.dev/meeting/EPxLy2Ow'
        axios.get("https://api.momnt.dev"+'/meeting/' + meeting_id)
            .then((response) => {
                setLoading(false);
                if (response.data){
                    setExists(true);
                    setName(response.data.info.name);
                    setDescription(response.data.info.description);
                    setLocation(response.data.info.location);
                    setLink(response.data.info.link);
                    setStartTime(response.data.info.startTime);
                    setEndTime(response.data.info.endTime);
                    // setTimeZone(response.data.info.timeZone);
                    setSelectedDays(parseSelectedDays(response.data.info.selectedDays));
                    setMeetingAvailabilities(response.data.availabilities);
                    setData(response.data.info)
                    setUsers(response.data.users);
                    setHost(response.data.host);
                } else{
                    setExists(false);
                }
            })
            .catch(error => {
                setLoading(false);
                setExists(false);
                console.error(error)
            });
    } 

    const sendAvailability = async (user) => {
        const data = {
            meeting_id: meeting_id,
            user: user,
            availability: availability
        }
        axios.post("https://api.momnt.dev"+'/meeting/'+meeting_id.toString(), data)
            .then((response) => {
                console.log(response)
            })
            .catch(error => {
                console.error(error)
            });
    };

    useEffect(()=>{
        async function fetchData() {
            await updateMeetingDetails();
        }
        fetchData();// eslint-disable-next-line
    }, [])

    useEffect(()=>{
        console.log(meetingAvailabilities);
        updateMeetingAvailabilities();// eslint-disable-next-line
    }, [meetingAvailabilities])

    function parseSelectedDays(s) {
        return s.map(d => dayjs(d))
    }

    if (loading) {
        return <div style={{height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <div className="spin"></div>
                </div>;
    }

    return (<div>
        {!exists && <div>Meeting not found</div>}
        {exists &&
        <Meeting 
            meeting_id={meeting_id} 
            exists={exists} 
            name={name} 
            description={description} 
            location={location} 
            link={link} 
            startTime={startTime} 
            endTime={endTime} 
            selectedDays={selectedDays} 
            // user={user} 
            // setUser={setUser} 
            availability={availability} 
            setAvailability={setAvailability} 
            sendAvailability={sendAvailability}
            avCount={avCount} 
            totalAvCount={meetingAvailabilities.length}
            avUsers={avUsers}
            data={data}
            avatars={users}
            host={host}
            updateMeetingDetails={updateMeetingDetails}
            updateMeetingAvailabilitiesClick={updateMeetingAvailabilitiesClick}
        /> }
         </div>
    );
}
