import React from "react";
import { Text } from '@mantine/core';

export default function TimeSelector({startTime, endTime, selectedDays, children}){

    function renderSelectedDays() {
        return selectedDays.map(x => <div>
                <div><Text fz="xs" ta="center" fw={600}>{x.format('ddd').toLowerCase()}</Text></div>
                <div><Text fz="xs" ta="center" fw={400}>{x.format('MM/DD').toLowerCase()}</Text></div>
            </div>)
    }

    function renderTimes() {
        return Array.from({length: (endTime.substring(0, 2) - startTime.substring(0, 2))%24}, (v, i) => i).map(i => {
            return <Text fz=".75rem" ta="center" fw={400}>{((((i + +startTime.substring(0, 2)) + 11) % 12 + 1).toString()+((i + +startTime.substring(0, 2)) >= 12 ? " pm":" am"))}</Text>
        })
    }

    return(
        <div className={'time-wrapper'}>
            <div className={'time-wrapper-grid'}>
                <span></span>
                <div className={'days'}>
                    {renderSelectedDays()}
                </div>
                <div className={'times'}>
                    {renderTimes()}
                </div>
                {children}
            </div>
        </div>
    )
}