import { Outlet, Link } from "react-router-dom";
import Profile from "../components/Profile";

export default function Root() {
	return (
		<>
			<div id="sidebar">
				<div id="logo">
					<h1>meetmeet</h1>
				</div>
				<nav>
					<ul>
					<li>
						<Link to={'new'}>
							<div className='link-icon'>💡</div>
							<div className='link-display'>new meeting</div>
						</Link>
					</li>
					<li>
						<Link to={'meeting'}>
							<div className='link-icon'>🗂</div>
							<div className='link-display'>all meetings</div>
						</Link>
					</li>
					</ul>
				</nav>
				<div>
					<Profile/>
				</div>
			</div>
			<div id="detail">
				<Outlet />
			</div>
		</>
	);
}